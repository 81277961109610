import React, { useState, useEffect } from 'react';
import './App.css';
import InvoiceTable from './components/InvoiceTable';
import { convertJSONtoXML, downloadPDF, ensureVATIDFormat } from './utils/xmlConverter';
import { xmlToCustomJson } from './utils/xmltojson';

function App() {
  const [activeTab, setActiveTab] = useState('incoming');
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jsonData, setJsonData] = useState(null);

  const fetchInvoices = async (cardType) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('https://api.fbsshop.de', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          serviceId: "5201",
          vendorId: "19999006",
          token: "A1453993-F6A5-43FB-9574-0A4CC7A85272",
          invoiceStatus: 0,
          cardType: cardType
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP hata! durum: ${response.status}`);
      }

      const data = await response.json();

      if (!data) {
        throw new Error('Veri alınamadı');
      }

      if (data.resCode === 200) {
        const processedInvoices = (data.dataset || []).map(invoice => ({
          ...invoice,
          eInvoiceStatus: invoice.eInvoiceStatus || 0,
          ourContactPerson: invoice.ourContactPerson || 'Contact Person',
          ourPhone: invoice.ourPhone || '+49 123456789',
          ourEmail: invoice.ourEmail || 'contact@company.de',
          ourIBAN: invoice.ourIBAN || 'DE89370400440532013000',
          ourBIC: invoice.ourBIC || 'DEUTDEBBXXX',
          ourCompanyName: invoice.ourCompanyName || 'Our Company GmbH',
          clientReference: invoice.clientReference || `REF_${invoice.invoiceNo}`,
          clientName: invoice.clientName || 'Client Company Name',
          ourVATID: ensureVATIDFormat(invoice.ourVATID),
          clientVATID: ensureVATIDFormat(invoice.clientVATID),
          supplierContactPerson: invoice.supplierContactPerson || 'Supplier Contact',
          supplierPhone: invoice.supplierPhone || '+49 987654321',
          supplierEmail: invoice.supplierEmail || 'contact@supplier.de'
        }));
        setInvoices(processedInvoices);
      } else {
        setError(data.resMessage || 'Bilinmeyen bir hata oluştu');
      }
    } catch (err) {
      setError(err.message || 'Faturaları yüklerken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const cardType = activeTab === 'outgoing' ? 2 : 1;
    fetchInvoices(cardType);
  }, [activeTab]);

  const handleDownloadXML = (invoice) => {
    // activeTab değerine göre XML tipini belirle
    const xmlType = activeTab === 'outgoing' ? 'outgoing' : 'incoming';
    const xmlContent = convertJSONtoXML(invoice, xmlType);

    const blob = new Blob([xmlContent], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `invoice_${invoice.invoiceNo}_${xmlType}.xml`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleConvertXMLToJson = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const xmlContent = event.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
        const json = xmlToCustomJson(xmlDoc);
        setJsonData(json);
      };

      reader.readAsText(file);
    };
    input.click();
  };

  return (
    <div className="container">
      <div className="flex items-center gap-4 mb-4">
        <h1 className="title flex-1">Faturalar</h1>
        <button
          onClick={handleConvertXMLToJson}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          XML'den JSON'a Çevir
        </button>
      </div>

      {jsonData && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg overflow-auto">
          <pre className="text-sm">{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
      )}

      <div className="tab-container">
        <div className="tab-list">
          <div
            className={`tab-item ${activeTab === 'outgoing' ? 'active' : ''}`}
            onClick={() => setActiveTab('outgoing')}
          >
            Gelen Faturalar
          </div>
          <div
            className={`tab-item ${activeTab === 'incoming' ? 'active' : ''}`}
            onClick={() => setActiveTab('incoming')}
          >
            Giden Faturalar
          </div>
        </div>
      </div>

      {loading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}

      {error && (
        <div className="error-alert">
          <strong>Hata!</strong>
          <span>{error}</span>
        </div>
      )}

      {!loading && !error && (
        <InvoiceTable
          data={invoices}
          type={activeTab === 'outgoing' ? 'Gelen' : 'Giden'}
          onDownloadXML={handleDownloadXML}
        />
      )}
    </div>
  );
}

export default App;
