export const getInvoice = async (invoiceRef) => {
    try {
        console.log('getInvoice çağrıldı, invoiceRef:', invoiceRef); // Debug için

        const response = await fetch('https://api.fbsshop.de', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceId: "5202",
                vendorId: "19999006",
                token: "A1453993-F6A5-43FB-9574-0A4CC7A85272",
                erpInvoiceRef: invoiceRef
            })
        });

        console.log('API yanıtı status:', response.status); // Debug için

        if (!response.ok) {
            throw new Error(`HTTP hata! durum: ${response.status}`);
        }

        const data = await response.json();
        console.log('API yanıt data:', data); // Debug için

        return data;
    } catch (error) {
        console.error('Fatura detayları alınırken hata:', error);
        throw error;
    }
};

export const getPDF = async (invoiceRef) => {
    try {
        console.log('getPDF çağrıldı, invoiceRef:', invoiceRef);

        const response = await fetch('https://api.fbsshop.de', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceId: "5203", // PDF servisi için
                vendorId: "19999006",
                token: "A1453993-F6A5-43FB-9574-0A4CC7A85272",
                invoiceRef: invoiceRef
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP hata! durum: ${response.status}`);
        }

        const data = await response.json(); // PDF URL'sini al
        return data.pdfUrl; // PDF URL'sini döndür
    } catch (error) {
        console.error('PDF alınırken hata:', error);
        throw error;
    }
};
