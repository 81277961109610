import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';

// Basit Alert Componenti
const Alert = ({ children, variant }) => (
    <div className={`alert ${variant === 'destructive' ? 'alert-error' : 'alert-success'}`}>
        {children}
    </div>
);

const AlertDescription = ({ children }) => <div>{children}</div>;

function LoginPage() {
    const navigate = useNavigate();
    const [vendorId, setVendorId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const savedCredentials = localStorage.getItem('rememberedCredentials');
        if (savedCredentials) {
            const { vendorId: savedVendorId, userName: savedUserName, rememberMe: savedRememberMe } = JSON.parse(savedCredentials);
            setVendorId(savedVendorId);
            setUserName(savedUserName);
            setRememberMe(savedRememberMe);
        }
    }, []);

    const handleRememberMe = (credentials) => {
        if (rememberMe) {
            localStorage.setItem('rememberedCredentials', JSON.stringify({
                vendorId: credentials.vendorId,
                userName: credentials.userName,
                rememberMe: true
            }));
        } else {
            localStorage.removeItem('rememberedCredentials');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (!vendorId || !userName || !password) {
            setError('VendorId, UserName and Password are required');
            setIsLoading(false);
            return;
        }

        const loginData = {
            serviceId: "5200",
            vendorId: vendorId,
            token: "F7F08918-7D1C-408E-9893-B32B2953A39F",
            userName: userName,
            password: password,
            lang: "tr",
            deviceManifacturer: "",
            deviceModel: "",
            deviceSerial: "",
            deviceOSName: "",
            deviceOSVersion: ""
        };

        try {
            const response = await fetch('https://api.fbsshop.de', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            const result = await response.json();

            if (response.ok && result.resCode === 200) {
                handleRememberMe({ vendorId, userName });

                // Başarılı giriş, App.js ekranına yönlendir
                sessionStorage.setItem('authToken', result.token);
                navigate('/app'); // App.js yoluna yönlendirme
            } else {
                // Hatalı giriş, hata mesajı göster
                setError(result.resMessage || 'Giriş başarısız. Lütfen tekrar deneyin.');
            }
        } catch (error) {
            console.error('Login sırasında hata:', error);
            setError('Bir hata oluştu. Lütfen tekrar deneyin.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <h2 className="login-title">Giriş Yap</h2>
            <form onSubmit={handleSubmit} className="login-form">
                {error && (
                    <Alert variant="destructive">
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}
                <div className="input-group">
                    <input
                        type="text"
                        id="vendorId"
                        placeholder="VendorId"
                        value={vendorId}
                        onChange={(e) => setVendorId(e.target.value)}
                        className="login-input"
                        required
                    />
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        id="userName"
                        placeholder="UserName"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        className="login-input"
                        required
                    />
                </div>
                <div className="input-group">
                    <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="login-input"
                        required
                    />
                </div>
                <div className="remember-me">
                    <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        className="remember-me-checkbox"
                    />
                    <label htmlFor="rememberMe" className="remember-me-label">Remember me</label>
                </div>
                <button
                    type="submit"
                    className="login-button"
                    disabled={isLoading}
                >
                    {isLoading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
                </button>
            </form>
            <a href="/forget-password" className="forget-password">
                Şifremi Unuttum?
            </a>
            <button
                onClick={() => navigate('/register')}
                className="create-account-button"
            >
                Hesap Oluştur
            </button>
        </div>
    );
}

export default LoginPage;
