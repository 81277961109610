import React, { useState, useEffect } from 'react';
import { Search, ChevronUp, ChevronDown, Filter, Download, Mail, FileText } from 'lucide-react';
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogCancel,
    AlertDialogAction,
} from "./ui/alert-dialog";
import { downloadPDF, convertJSONtoXML } from '../utils/xmlConverter';
import { getInvoice, getPDF } from '../requests';

const BulkActionButtons = ({ selectedCount, onBulkXML, onBulkPDF, onBulkEmail }) => {
    const [isOpen, setIsOpen] = useState(false);

    if (selectedCount === 0) return null;

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="fixed bottom-4 right-4 bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700 flex items-center gap-2"
            >
                <Filter className="h-4 w-4" />
                Seçili Faturalar İçin İşlem ({selectedCount})
            </button>

            <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            Toplu İşlemler ({selectedCount} Fatura)
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            <div className="space-y-4 mt-4">
                                <button
                                    onClick={onBulkXML}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                >
                                    <FileText className="h-4 w-4" />
                                    XML İndir
                                </button>

                                <button
                                    onClick={onBulkPDF}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                >
                                    <Download className="h-4 w-4" />
                                    PDF İndir
                                </button>

                                <button
                                    onClick={onBulkEmail}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
                                >
                                    <Mail className="h-4 w-4" />
                                    Mail Gönder
                                </button>
                            </div>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Kapat</AlertDialogCancel>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

const InvoiceTable = ({ data = [], type, onDownloadXML }) => {
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        invoiceStatus: '',
        startDate: '',
        endDate: '',
        minAmount: '',
        maxAmount: '',
    });
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [error, setError] = useState(null);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(date);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }).format(amount).replace('€', '') + ' €';
    };

    const getInvoiceStatus = (status) => {
        switch (status) {
            case 0: return 'Gönderilmedi';
            case 1: return 'Cevap Bekleniyor';
            case 2: return 'Onaylandı';
            default: return 'Bilinmiyor';
        }
    };

    const toggleRowSelection = (invoiceRef) => {
        setSelectedRows(prev =>
            prev.includes(invoiceRef)
                ? prev.filter(ref => ref !== invoiceRef)
                : [...prev, invoiceRef]
        );
    };

    const toggleSelectAll = () => {
        if (selectedRows.length === filteredInvoices.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(filteredInvoices.map(invoice => invoice.invoiceRef));
        }
    };

    const handleSort = (key) => {
        if (sortConfig.key === key) {
            setSortConfig({
                key,
                direction: sortConfig.direction === 'asc' ? 'desc' : 'asc'
            });
        } else {
            setSortConfig({ key, direction: 'asc' });
        }

        const sorted = [...filteredInvoices].sort((a, b) => {
            // Sayısal değerler için özel karşılaştırma
            if (['invoiceNetTotal', 'invoiceVatTotal', 'invoiceGrossTotal'].includes(key)) {
                return sortConfig.direction === 'asc'
                    ? Number(a[key]) - Number(b[key])
                    : Number(b[key]) - Number(a[key]);
            }

            // Tarih için özel karşılaştırma
            if (key === 'invoiceDate') {
                return sortConfig.direction === 'asc'
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            }

            // Diğer string değerler için
            if (sortConfig.direction === 'asc') {
                return (a[key] || '').localeCompare(b[key] || '');
            }
            return (b[key] || '').localeCompare(a[key] || '');
        });

        setFilteredInvoices(sorted);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 0: return 'text-red-600';
            case 1: return 'text-yellow-600';
            case 2: return 'text-green-600';
            default: return 'text-gray-600';
        }
    };

    const handleActionClick = async (e, invoice) => {
        e.stopPropagation();
        try {
            console.log('İşlem yapılan fatura:', invoice);
            setSelectedInvoice(invoice);
            setIsDialogOpen(true);

            const response = await getInvoice(invoice.invoiceRef);
            if (response && response.resCode === 200) {
                setInvoiceDetails(response);
            } else {
                setError('Fatura detayları alınamadı');
            }
        } catch (error) {
            console.error('Fatura detayları alınırken hata:', error);
            setError('Fatura detayları alınamadı');
        }
    };

    const handleXMLDownload = async () => {
        try {
            if (!invoiceDetails) {
                console.error('Fatura detayları bulunamadı');
                return;
            }

            console.log('XML oluşturuluyor...', invoiceDetails);

            // PDF'i al
            const pdfBlob = await getPDF(invoiceDetails.invoiceRef); // PDF'i al
            invoiceDetails.pdfBlob = pdfBlob; // PDF'i invoiceDetails'a ekle

            const xmlContent = await convertJSONtoXML(invoiceDetails); // XML'i oluştur

            const blob = new Blob([xmlContent], { type: 'text/xml' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `invoice_${invoiceDetails.invoiceGeneral[0].invoiceID}.xml`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);

            setIsDialogOpen(false);
        } catch (error) {
            console.error('XML indirme hatası:', error);
            setError('XML indirme işlemi başarısız oldu');
        }
    };

    const handlePDFDownload = async () => {
        try {
            if (!invoiceDetails) {
                throw new Error('Fatura detayları bulunamadı');
            }

            await downloadPDF(invoiceDetails);
            setIsDialogOpen(false);
        } catch (error) {
            console.error('PDF indirme hatası:', error);
            setError('PDF indirme işlemi başarısız oldu: ' + error.message);
        }
    };

    const handleEmailSend = () => {
        if (selectedInvoice.eInvoiceStatus !== 0) {
            setIsDialogOpen(false);
            setIsErrorDialogOpen(true);
            return;
        }

        console.log('E-posta gönderme işlemi:', selectedInvoice);
        setIsDialogOpen(false);
    };

    const handleBulkXML = async () => {
        try {
            const selectedInvoices = filteredInvoices.filter(invoice =>
                selectedRows.includes(invoice.invoiceRef)
            );
            for (const invoice of selectedInvoices) {
                await onDownloadXML(invoice);
            }
            setSelectedRows([]);
        } catch (error) {
            console.error('Toplu XML indirme hatası:', error);
        }
    };

    const handleBulkPDF = async () => {
        try {
            const selectedInvoices = filteredInvoices.filter(invoice =>
                selectedRows.includes(invoice.invoiceRef)
            );
            for (const invoice of selectedInvoices) {
                await downloadPDF(invoice);
            }
            setSelectedRows([]);
        } catch (error) {
            console.error('Toplu PDF indirme hatası:', error);
        }
    };

    const handleBulkEmail = async () => {
        console.log('Toplu e-posta gönderme:', selectedRows);
    };

    useEffect(() => {
        const filtered = data.filter(invoice => {
            const matchesSearch = (
                invoice.invoiceNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                invoice.clientName?.toLowerCase().includes(searchTerm.toLowerCase())
            );

            const matchesStatus = !filters.invoiceStatus ||
                invoice.eInvoiceStatus.toString() === filters.invoiceStatus;

            const invoiceDate = new Date(invoice.invoiceDate);
            const matchesStartDate = !filters.startDate ||
                invoiceDate >= new Date(filters.startDate);
            const matchesEndDate = !filters.endDate ||
                invoiceDate <= new Date(filters.endDate);

            const amount = Number(invoice.invoiceNetTotal);
            const matchesMinAmount = !filters.minAmount || amount >= Number(filters.minAmount);
            const matchesMaxAmount = !filters.maxAmount || amount <= Number(filters.maxAmount);

            return matchesSearch &&
                matchesStatus &&
                matchesStartDate &&
                matchesEndDate &&
                matchesMinAmount &&
                matchesMaxAmount;
        });

        setFilteredInvoices(filtered);
    }, [data, searchTerm, filters]);

    const columns = [
        { key: 'eInvoiceStatus', label: 'Fatura Durumu', sortable: true },
        { key: 'invoiceDate', label: 'Fatura Tarihi', sortable: true },
        { key: 'invoiceNo', label: 'Fatura No', sortable: true },
        { key: 'invoiceType', label: 'Fatura Tipi', sortable: true },
        { key: 'clientName', label: type === 'Giden' ? 'Gönderen' : 'Müşteri', sortable: true },
        {
            key: 'invoiceNetTotal',
            label: 'Net Tutar',
            sortable: true,
            numeric: true
        },
        {
            key: 'invoiceVatTotal',
            label: 'KDV',
            sortable: true,
            numeric: true
        },
        {
            key: 'invoiceGrossTotal',
            label: 'Brüt Tutar',
            sortable: true,
            numeric: true
        },
        { key: 'action', label: 'İşlem', sortable: false }
    ];

    const renderCellContent = (invoice, column) => {
        switch (column.key) {
            case 'invoiceDate':
                return formatDate(invoice[column.key]);
            case 'invoiceNetTotal':
            case 'invoiceVatTotal':
            case 'invoiceGrossTotal':
                return formatCurrency(invoice[column.key]);
            case 'eInvoiceStatus':
                return (
                    <span className={getStatusColor(invoice[column.key])}>
                        {getInvoiceStatus(invoice[column.key])}
                    </span>
                );
            case 'action':
                return (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log('Tıklanan fatura:', invoice);
                            handleActionClick(e, invoice);
                        }}
                        className="px-2 py-1 bg-blue-500 text-white text-xs rounded hover:bg-blue-600"
                    >
                        İşlemler
                    </button>
                );
            default:
                return invoice[column.key];
        }
    };

    return (
        <div className="relative">
            {/* Search, Filter, and Bulk Action Controls */}
            <div className="flex items-center gap-4 mb-4">
                <div className="flex-1 relative">
                    <input
                        type="text"
                        placeholder={`${type} faturalarda ara...`}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                </div>
                <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                    <Filter className="h-5 w-5" />
                </button>
                <div className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        checked={selectedRows.length === filteredInvoices.length}
                        onChange={toggleSelectAll}
                        id="select-all"
                        className="h-4 w-4 rounded border-gray-300 cursor-pointer"
                    />
                    <label htmlFor="select-all" className="cursor-pointer">Hepsini Seç</label>
                </div>
                {/* Toplu İşlem butonu */}
                <BulkActionButtons
                    selectedCount={selectedRows.length}
                    onBulkXML={handleBulkXML}
                    onBulkPDF={handleBulkPDF}
                    onBulkEmail={handleBulkEmail}
                />
            </div>

            {/* Filter options */}
            {showFilters && (
                <div className="mb-4 p-4 border rounded-lg bg-gray-50">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Fatura Durumu</label>
                            <select
                                name="invoiceStatus"
                                value={filters.invoiceStatus}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            >
                                <option value="">Tümü</option>
                                <option value="0">Gönderilmedi</option>
                                <option value="1">Cevap Bekleniyor</option>
                                <option value="2">Onaylandı</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Başlangıç Tarihi</label>
                            <input
                                type="date"
                                name="startDate"
                                value={filters.startDate}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Bitiş Tarihi</label>
                            <input
                                type="date"
                                name="endDate"
                                value={filters.endDate}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Minimum Tutar</label>
                            <input
                                type="number"
                                name="minAmount"
                                value={filters.minAmount}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Maksimum Tutar</label>
                            <input
                                type="number"
                                name="maxAmount"
                                value={filters.maxAmount}
                                onChange={handleFilterChange}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Table */}
            <div className="border rounded-lg overflow-hidden">
                <table className="w-full table-auto divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="w-8 px-2 py-3">
                                <input
                                    type="checkbox"
                                    checked={selectedRows.length === filteredInvoices.length}
                                    onChange={toggleSelectAll}
                                    className="h-4 w-4 rounded border-gray-300"
                                />
                            </th>
                            {columns.map((column) => (
                                <th
                                    key={column.key}
                                    className={`px-3 py-3 text-center text-xs font-medium text-gray-700 ${column.sortable ? 'cursor-pointer hover:bg-gray-100' : ''
                                        }`}
                                    onClick={() => column.sortable && handleSort(column.key)}
                                >
                                    <div className="flex items-center justify-center gap-1">
                                        {column.label}
                                        {column.sortable && sortConfig.key === column.key && (
                                            sortConfig.direction === 'ascending' ?
                                                <ChevronUp className="h-3 w-3" /> :
                                                <ChevronDown className="h-3 w-3" />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {filteredInvoices.map((invoice) => (
                            <tr
                                key={invoice.invoiceRef}
                                className={`
                                    hover:bg-gray-50 cursor-pointer text-xs
                                    ${selectedRows.includes(invoice.invoiceRef) ? 'bg-blue-50' : ''}
                                `}
                                onClick={() => toggleRowSelection(invoice.invoiceRef)}
                            >
                                <td className="px-2 py-3">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(invoice.invoiceRef)}
                                        onChange={() => toggleRowSelection(invoice.invoiceRef)}
                                        onClick={(e) => e.stopPropagation()}
                                        className="h-4 w-4 rounded border-gray-300"
                                    />
                                </td>
                                {columns.map((column) => (
                                    <td key={column.key} className={`px-3 py-3 ${['invoiceNetTotal', 'invoiceVatTotal', 'invoiceGrossTotal'].includes(column.key)
                                        ? 'text-right'
                                        : 'text-left'
                                        }`}>
                                        {renderCellContent(invoice, column)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Seçili fatura sayısını göster */}
            {selectedRows.length > 0 && (
                <div className="mt-4 text-sm text-gray-600">
                    {selectedRows.length} fatura seçildi
                </div>
            )}

            <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <AlertDialogContent className="max-w-md">
                    <AlertDialogHeader>
                        <AlertDialogTitle>Fatura İşlemleri</AlertDialogTitle>
                        <AlertDialogDescription>
                            <div className="space-y-3">
                                <button
                                    onClick={handleXMLDownload}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                >
                                    <FileText className="h-4 w-4" />
                                    XML İndir
                                </button>

                                <button
                                    onClick={handlePDFDownload}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                >
                                    <Download className="h-4 w-4" />
                                    PDF İndir
                                </button>

                                <button
                                    onClick={handleEmailSend}
                                    className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
                                >
                                    <Mail className="h-4 w-4" />
                                    Mail Gönder
                                </button>
                            </div>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Kapat</AlertDialogCancel>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <AlertDialog open={isErrorDialogOpen} onOpenChange={setIsErrorDialogOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle className="text-red-600">
                            Fatura Zaten Gönderilmiş
                        </AlertDialogTitle>
                        <AlertDialogDescription className="mt-4">
                            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                                <div className="flex items-center gap-2 text-red-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                    <span className="font-medium">Hata</span>
                                </div>
                                <p className="mt-2 text-sm text-red-600">
                                    Bu fatura daha önce zaten gönderilmiş. Tekrar gönderilemez.
                                </p>
                            </div>
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogAction
                            onClick={() => setIsErrorDialogOpen(false)}
                            className="bg-red-600 hover:bg-red-700 text-white"
                        >
                            Tamam
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
};

export default InvoiceTable;