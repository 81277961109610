import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import App from './App';

const ProtectedRoute = ({ children }) => {
    const token = sessionStorage.getItem('authToken');

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/app" element={
                    <ProtectedRoute>
                        <App />
                    </ProtectedRoute>
                } />
                <Route path="/" element={<Navigate to="/app" replace />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;